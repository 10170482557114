import { useMediaQuery } from '@kaliber/use-media-query'

import { BackgroundVideo } from '/features/buildingBlocks/BackgroundVideo'
import { ContainerLg } from '/features/buildingBlocks/Container'
import { Icon } from '/features/buildingBlocks/Icon'

import doubleChevronIcon from '/images/icons/double-chevron-down.raw.svg'

import poster from '/images/videoPosterWavemakers.jpg'

import mediaStyles from '/cssGlobal/media.css'
import styles from './WavemakersHero.css'

export function WavemakersHero({ hero }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const { heading, subheading, heroVideoDesktop, heroVideoMobile } = hero
  const videoSources = isViewportMd ? heroVideoDesktop : heroVideoMobile

  return (
    <section data-x='hero' className={styles.component}>
      <div className={styles.heroWrapper}>
        <div className={styles.heroContainer}>
          <ContainerLg>
            <span className={styles.headingContainer} aria-label={`${heading}. ${subheading}.`}>
              {heading && <h1 className={styles.heading}><strong>{heading}</strong></h1>}
              {subheading && <h2 className={styles.subheading}>{subheading}</h2>}
            </span>
          </ContainerLg>

          <ScrollNudge isActive layoutClassName={styles.scrollNudgeLayout} />

          {videoSources && (
            <BackgroundVideo
              sources={videoSources}
              layoutClassName={styles.videoLayout}
              {...{ poster }}
            />
          )}
        </div>
      </div>
    </section>
  )
}

function ScrollNudge({ isActive, layoutClassName = undefined }) {
  return (
    <button
      onClick={handleClick}
      className={cx(styles.componentScrollNudge, isActive && styles.isActive, layoutClassName)}
    >
      <Icon icon={doubleChevronIcon} layoutClassName={styles.iconLayout} />
    </button>
  )

  function handleClick() {
    window.scrollBy({
      top: window.innerHeight,
      behavior: 'smooth'
    })
  }
}
